define("ember-cli-head/components/head-layout", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#in-element this.headElement insertBefore=null}}
    {{! template-lint-disable no-forbidden-elements }}
    <meta name="ember-cli-head-start" content=""><HeadContent /><meta name="ember-cli-head-end" content="">
  {{/in-element}}
  
  */
  {
    "id": "5Hr1QmAy",
    "block": "[[[40,[[[1,\"  \"],[10,\"meta\"],[14,3,\"ember-cli-head-start\"],[14,\"content\",\"\"],[12],[13],[8,[39,2],null,null,null],[10,\"meta\"],[14,3,\"ember-cli-head-end\"],[14,\"content\",\"\"],[12],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"headElement\"]]],null],null]],[],false,[\"in-element\",\"-in-el-null\",\"head-content\"]]",
    "moduleName": "ember-cli-head/components/head-layout.hbs",
    "isStrictMode": false
  });
  let HeadLayout = (_dec = (0, _service.inject)('-document'), (_class = class HeadLayout extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "document", _descriptor, this);
      /**
       * If true, this will tear down any existing head on init of this component.
       * This is useful if there is a head built with fastboot - it will then be torn down when this is initialized in the browser.
       * If you do not want this behavior, you can set this to false.
       * @public
       */
      _defineProperty(this, "shouldTearDownOnInit", true);
      /**
       * The element to render into. Defaults to <head> in `init`, overridable for our own tests only.
       * @private
       */
      _defineProperty(this, "headElement", this.args.headElement || this.document.head);
      if (this.shouldTearDownOnInit) {
        this._tearDownHead();
      }
    }

    /**
     * Tear down any previous head, if there was one.
     * @private
     */
    _tearDownHead() {
      if (this._isFastboot()) {
        return;
      }

      // clear fast booted head (if any)
      let document = this.document;
      let startMeta = document.querySelector('meta[name="ember-cli-head-start"]');
      let endMeta = document.querySelector('meta[name="ember-cli-head-end"]');
      if (startMeta && endMeta) {
        let el = startMeta.nextSibling;
        while (el && el !== endMeta) {
          document.head.removeChild(el);
          el = startMeta.nextSibling;
        }
        document.head.removeChild(startMeta);
        document.head.removeChild(endMeta);
      }
    }
    _isFastboot() {
      return typeof FastBoot !== 'undefined';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "document", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HeadLayout;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HeadLayout);
});